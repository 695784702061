import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box, Typography, Paper, IconButton, Grid, MenuItem, Menu, Button, useMediaQuery, useTheme } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';
import ShareIcon from '@mui/icons-material/Share';
import SendIcon from '@mui/icons-material/Send';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import logo from '../../assets/logo/logo_image.jpg';
import ViewQuotations from './ViewQuotations';


function Quotation() {
  // const logo = `${process.env.PUBLIC_URL}/logo/logo_image.jpg`;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  function createData(Id, DescriptionOfGoodes, QualityLevel, Quantity, unitprice, Totalamount) {
    return { Id, DescriptionOfGoodes, QualityLevel, Quantity, unitprice, Totalamount };
  }

  const rows = [
    createData(1, 'Burnt clay bricks', 'A', 1, 100, 100),
    createData(2, 'Concrete bricks', 'B', 1, 100, 100),
    createData(3, 'Fly ash bricks', 'C', 1, 100, 100),
    createData(4, 'Fire bricks', 'D', 1, 100, 100),
    createData(5, 'Sand-lime bricks', 'E', 1, 100, 100),
    createData(5, 'Calcium silicate bricks', 'E', 1, 100, 100),
    createData(5, 'Sun-dried bricks', 'E', 1, 100, 100),
    createData(5, 'Lime bricks', 'E', 1, 100, 100),
  ];

  const Container = styled(Paper)({
    padding: '20px',
    height: 'auto',
    width: '100%',
    maxWidth: '950px',
    marginTop:"10px",
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
  });

  const TableContainer = styled('div')({
    padding: '10px',
    marginTop: '50px',
  });

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },

    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
    
      <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          send
        </Button>
        <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
          <MenuItem onClick={handleCloseMenu}>
            <ShareIcon sx={{ marginRight: 1 }} /> Share
          </MenuItem>
          <MenuItem onClick={handleCloseMenu}>
            <SendIcon sx={{ marginRight: 1 }} /> Send
          </MenuItem>
          <MenuItem onClick={handleCloseMenu}>
            <VisibilityIcon sx={{ marginRight: 1 }} /> View
          </MenuItem>
          <MenuItem onClick={handleCloseMenu}>
            <PrintIcon sx={{ marginRight: 1 }} /> Print
          </MenuItem>
          <MenuItem onClick={handleCloseMenu}>
            <DownloadIcon sx={{ marginRight: 1 }} /> Download
          </MenuItem>
        </Menu>
      </Box>
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
            // alignItems: isMobile ? 'center' : 'flex-start',
          }}
        >
          <Box sx={{ marginBottom: isMobile ? 2 : 0 }}>
            <img src={logo} alt="Logo" style={{ width: isMobile ? '80px' : '100px', marginBottom: '8px' }} />
          </Box>
          <Box sx={{ textAlign: 'right' }}>
            <Typography
              variant="h5"
              component="div"
              sx={{ display: 'inline-block', marginRight: isMobile ? 0 : '16px' }}
            >
              Quotation
            </Typography>
          </Box>
        </Box>

        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6} sx={{ textAlign: isMobile ? 'center' : 'left' }}>
            <Typography variant="subtitle1" fontWeight="bold">
              From:
            </Typography>
            <Typography variant="body2">Company Name</Typography>
            <Typography variant="body2">123 Business Street</Typography>
            <Typography variant="body2">City, State, ZIP</Typography>
            <Typography variant="body2">Email: contact@company.com</Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ textAlign: isMobile ? 'center' : 'right' }}>
            <Typography variant="subtitle1" fontWeight="bold">
              To:
            </Typography>
            <Typography variant="body2">Client Name</Typography>
            <Typography variant="body2">456 Client Avenue</Typography>
            <Typography variant="body2">City, State, ZIP</Typography>
            <Typography variant="body2">Email: client@example.com</Typography>
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Description of Goods</StyledTableCell>
                <StyledTableCell align="right">Quality Level</StyledTableCell>
                <StyledTableCell align="right">Quantity</StyledTableCell>
                <StyledTableCell align="right">Unit Price INR</StyledTableCell>
                <StyledTableCell align="right">Total Amount</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <StyledTableCell component="th" scope="row">
                    {row.Id}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.DescriptionOfGoodes}</StyledTableCell>
                  <StyledTableCell align="center">{row.QualityLevel}</StyledTableCell>
                  <StyledTableCell align="center">{row.Quantity}</StyledTableCell>
                  <StyledTableCell align="center">{row.unitprice}</StyledTableCell>
                  <StyledTableCell align="center">{row.Totalamount}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography sx={{ fontSize: '11px', lineHeight: 1.2, color: '#333', mt: 5 }}>
          What We Offer: Export & Domestic Packaging Solution, Stretch Wrapping Film, Bopp Type, Air bubble
          RollCorrugated Roll,Edge Protector, HDPE / LLDPE Bag, Air Bubble Bag / Pouch, VCI Paper & Poly Bag,Tarpaulin
          Roll /Cover, SilicaGel, Desiccant , PET/PP/MS Strapping Roll & Clips, EPE Foam, Crosslink Foam, Antistatic Air
          Bubble Roll, Etc More Details Please visit following website : http://www.ssicwoodenpackaging.com/ “
        </Typography>
      </Container>
    </>
  );
}

export default Quotation;

// React and third-party libraries
import { Component } from 'react';
import { Person, Favorite, ShoppingCart } from '@mui/icons-material';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { Box, Typography } from '@mui/material';
// Project paths and configuration
import { PATH_AUTH,  PATH_PAGE } from '../../../routes/paths';
import { PATH_AFTER_LOGIN } from '../../../config';

// Custom components
import Iconify from '../../../components/iconify';
import FilterDrawer from '../../../components/settings/drawer/FilterDrawer';


const navConfig = [
 

  {
    title: (
      <div
        style={{
          position: 'relative',
          right: '40px',
        
        }}
      >
        <FilterDrawer cart />
      </div>
    ),
   
    path: '/',
  },

  {
    title: (
      <div style={{
        position:"relative",
        right:"20px",
      }}>
        <FilterDrawer profile />
      </div>
    ),
    path: '/',
  },

  // {
  //   title: (
  //     <div
  //       style={{
  //         position: 'absolute',
  //         left: '200px',
  //         top: '-10px',
  //         borderRadius: '100px',
  //         padding: '2px',
  //         background: 'linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)',
  //         transition: 'transform 0.3s, box-shadow 0.3s',
  //         '&:hover': {
  //           transform: 'scale(2)',
  //           boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
  //         },
  //       }}
  //     >
  //       <FilterDrawer profile />
  //     </div>
  //   ),
  //   path: '/',
  // },
];

export default navConfig;

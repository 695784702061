import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Dialog, DialogTitle, DialogContent, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { alpha } from '@mui/system';
import Invoice from './Invoice';
import Quotation from './Quotation';






DialogBox.propTypes = {
  invoice : PropTypes.bool,
  quotations: PropTypes.bool,
};

export default function DialogBox({ invoice ,quotations }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {invoice && <VisibilityIcon onClick={handleToggle}>Cart</VisibilityIcon>}
      {quotations && <VisibilityIcon onClick={handleToggle}>Quotations</VisibilityIcon>}

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: quotations ? 'white' : invoice ? 'white' : alpha(theme.palette.background.default, 0.9),
            // width: cart ? '600px' : profile ? '500px' : quotations ? '600px' : '500px',
            // boxShadow: `-24px 12px 40px 0 ${alpha(
            //   theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
            //   0.16
            // )}`,
          },
        }}
      >
        <DialogTitle>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
          {/* <Typography variant="h6">{invoice ? 'invoice' : quotations ? 'quotations' : 'Settings'}</Typography> */}
        </DialogTitle>
        <DialogContent>{invoice ? <Invoice /> : 
        quotations ? <Quotation />
         : 'Settings'}</DialogContent>
      </Dialog>
    </>
  );
}

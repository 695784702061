import React, { useState, Suspense, lazy, useCallback } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';

import { styled } from '@mui/system';

import DialogBox from './DialogBox';
// import Quotation from './Quotation';

const Quotation = lazy(() => import('./Quotation'));

// Function to format the date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString(); // This will format the date in MM/DD/YYYY format
};

// Function to create row data
function createData(sno, quotationsNo, date, view) {
  return { sno, quotationsNo, date, view };
}

// Example data
const rows = [
  createData(1, 1017567990, '2024-11-03', 'View'),
  createData(2, 1024676878, '2024-11-04', 'View'),
  createData(3, 1039797888, '2024-11-05', 'View'),
  createData(4, 1046766456, '2024-11-06', 'View'),
  createData(5, 1056768899, '2024-11-07', 'View'),
];

export default function ViewQuotations() {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: '8px',
          border: '1px solid #ccc',
        }}
      >
        <Table sx={{ minWidth: 950 }} size="medium" aria-label="a styled table">
          <TableHead>
            <TableRow>
              <StyledTableCell>SNO</StyledTableCell>
              <StyledTableCell align="center">QuotationsNO</StyledTableCell>
              <StyledTableCell align="center">Date</StyledTableCell>
              <StyledTableCell align="center">view</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.sno}
                sx={{
                  '&:nth-of-type(even)': { backgroundColor: '#e8e8e8' },
                  '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
                  '&:hover': { backgroundColor: '#d0d0d0', cursor: 'pointer' },
                }}
              >
                <TableCell component="th" scope="row">
                  {row.sno}
                </TableCell>
                <TableCell align="center">#{row.quotationsNo}</TableCell>
                <TableCell align="center">{formatDate(row.date)}</TableCell>
                <TableCell align="center">
                  <IconButton color="primary" aria-label="view">
                    <DialogBox open={dialogOpen} onClose={handleCloseDialog} quotations />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

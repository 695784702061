import React,{useState} from 'react';
import { Typography, Avatar, MenuItem, Stack, Box, Button } from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import { Home, AccountCircle, Settings, Subscriptions, Work, ExitToApp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '8px',
});

const StyledAvatar = styled(Avatar)({
  width: '70px',
  height: '70px',
  marginBottom: '10px',
  border: '2px solid #fff',
  boxShadow: 'rgb(255 86 48 / 56%) 0px 1px 8px 0px, #fff5f3 0px 0px 0px 0px',
});

const StyledMenuItem = styled(MenuItem)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '8px 8px',
  borderRadius: '8px',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: '#f0f0f0',
    transform: 'scale(1.02)',
  },
});

const StyledButton = styled(Button)({
  width: '90%',
  height: '50px',
  marginTop: '270px',
  borderRadius: '8px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    backgroundColor: '#d32f2f',
    boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.2)',
  },
});

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
});




function AccountPopover() {
  const [value, setValue] = useState('');

  const handleMenuItemClick = () => {
    console.log('Menu clicked!');
  };
 

  return (
    <>
      <StyledContainer>
        <StyledAvatar alt="Sowmya" src="/assets/images/portraits/portrait_1.jpg" />
        <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
          Sowmya
        </Typography>
      </StyledContainer>

      <ContentWrapper sx={{ px: 2, pt: 2 }}>
        <Stack direction="column" sx={{ width: '100%' }}>
          <StyledMenuItem onClick={() => handleMenuItemClick('0')}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Home />
              <span style={{ marginLeft: '10px' }}>Home</span>
            </Box>
          </StyledMenuItem>

          <StyledMenuItem onClick={() => handleMenuItemClick('1')}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AccountCircle />
              <span style={{ marginLeft: '10px' }}>Profile</span>
            </Box>
          </StyledMenuItem>

          <StyledMenuItem onClick={() => handleMenuItemClick('2')}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Settings />
              <span style={{ marginLeft: '10px' }}>Settings</span>
            </Box>
          </StyledMenuItem>

          <StyledMenuItem onClick={() => handleMenuItemClick('3')} setValue={setValue}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Subscriptions />
              <span style={{ marginLeft: '10px' }}>Quotations</span>
            </Box>
          </StyledMenuItem>

          <StyledMenuItem onClick={() => handleMenuItemClick('4')}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Work />
              <span style={{ marginLeft: '10px' }}>Projects</span>
            </Box>
          </StyledMenuItem>
        </Stack>
      </ContentWrapper>

      <Box sx={{ textAlign: 'center' }}>
        <StyledButton variant="contained" color="error" startIcon={<ExitToApp />}>
          Logout
        </StyledButton>
      </Box>
    </>
  );
}



export default AccountPopover;

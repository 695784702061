import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import '../../pages/Styles.css'
// @mui
import { useTheme, styled} from '@mui/material/styles';
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from '@mui/material';
import { RoomOutlined, Search } from '@mui/icons-material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// config
import { HEADER } from '../../config';
// components
import Logo from '../../components/logo';
import navConfig from './nav/config';
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
import CategoriesNav from './nav/CategoriesNav';




const SearchContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '40px',
  width: '40%',
  padding: '4px 25px',
  marginLeft:30,
  backgroundColor: theme.palette.grey[100],
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  padding: '2px 10px',
  cursor: 'pointer',
  '&:hover': {
    border: '1px solid white',
    borderRadius: '5px',
  },
}));



export default function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const [searchCategory, setSearchCategory] = useState('all');
  const [searchText, setSearchText] = useState('');

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearch = () => {
    console.log(`Searching for "${searchText}" in category "${searchCategory}"`);
    // Add your search logic here
  };

  const handleMenuOpen = (event, category) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
    setActiveCategory(category);
  };

  // Close menu on mouse leave
  const handleMenuClose = () => {
    setMenuOpen(false);
    setActiveCategory(null);
  };

  const carouselRef = useRef(null);
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'md');
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  return (
    <>
      <AppBar ref={carouselRef} sx={{ boxShadow: 0 }}>
        <Toolbar
          disableGutters
          sx={{
            backgroundColor: '#131921',
            padding: '0px 10px',
            height: {
              xs: HEADER.H_MOBILE,
              md: HEADER.H_MAIN_DESKTOP,
            },
            transition: theme.transitions.create(['height'], {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.shorter,
            }),
            // ...(isOffset && {
            //   ...bgBlur({ color: theme.palette.background.default }),
            //   height: {
            //     md: HEADER.H_MAIN_DESKTOP - 16,
            //   },
            // }),
          }}
        >
          {/* <Container sx={{ display: 'flex', alignItems: 'center' }}> */}

          <Logo sx={{ marginRight: 5 }} />
          <Box sx={{ color: 'white', padding: '5px 20px' }}>
            <Typography variant="p" sx={{ fontSize: '14px' }}>
              Delivering to Hyderabad 500001
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: '900', fontSize: '19px', display: 'flex' }}>
              <RoomOutlined sx={{ fontSize: '28px' }} />
              Update Location
            </Typography>
          </Box>
          <SearchContainer>
            {/* Dropdown for selecting category */}
            <Box
              component="select"
              sx={{ minWidth: 80, border: 'none', backgroundColor: 'transparent', marginRight: '8px' }}
              value={searchCategory}
              onChange={(e) => setSearchCategory(e.target.value)}
            >
              <option value="all">Products</option>
              <option value="category1">Category 1</option>
              <option value="category2">Category 2</option>
            </Box>

            {/* Input for search text */}

            {/* <div className="typewriter-container"> */}
              {/* Static placeholder for typewriter effect */}
              {/* {!searchText && <span className="typewriter-placeholder">Search Product...</span>} */}
              <input
                className=".typewriter-input"
                placeholder="Search Product..."
                style={{
                  flex: 1,
                  padding: '8px',
                  border: 'none',
                  outline: 'none',
                  fontSize: '18px',
                }}
                value={searchText}
                onChange={() => handleInputChange()}
              />
            {/* </div> */}
            <IconButton
              sx={{ color: 'black' }}
              // onClick={handleSearch}
              aria-label="Search"
            >
              <Search />
            </IconButton>
          </SearchContainer>

          <Box sx={{ flexGrow: 1 }} />

          {isDesktop && <NavDesktop isOffset={isOffset} data={navConfig} />}
          {!isDesktop && <NavMobile isOffset={isOffset} data={navConfig} />}
          {/* </Container> */}
        </Toolbar>

        {isOffset && <Shadow />}
        <Box sx={{ backgroundColor: '#232f3e', padding: '5px 10px' }}>
          <Box sx={{ display: 'flex', color: 'white', justifyContent: 'center' }}>
            <NavDesktop isOffset={isOffset} data={CategoriesNav} />
          </Box>
        </Box>

        {/* {isDesktop && (
          <Box onMouseEnter={(e) => handleMenuOpen(e, 'categories')} onMouseLeave={handleMenuClose}>
            <NavDesktop isOffset={isOffset} data={CategoriesNav} />
            <Menu
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleMenuClose}
              onMouseEnter={() => setMenuOpen(true)}
              onMouseLeave={handleMenuClose}
            >
              {activeCategory &&
                activeCategory.children &&
                activeCategory.children.map((section, index) => (
                  <Box key={index}>
                    <Typography sx={{ padding: '8px', fontWeight: 'bold' }}>{section.subheader}</Typography>
                    {section.items.map((item, itemIndex) => (
                      <MenuItem key={itemIndex}>{item.title}</MenuItem>
                    ))}
                  </Box>
                ))}
            </Menu>
          </Box>
        )} */}
      </AppBar>
    </>
  );
}

// ----------------------------------------------------------------------

Shadow.propTypes = {
  sx: PropTypes.object,
};

function Shadow({ sx, ...other }) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}

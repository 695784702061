import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Typography, Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

const StyledRoot = styled('div')({
  padding: '20px',
  width: '230px', // Set width similar to your image
  backgroundColor: '#fff',
  border: '1px solid #e0e0e0',
  borderRadius: '8px',
});

const SectionTitle = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '1rem',
  marginTop: '15px',
  marginBottom: '10px',
  color: '#333',
});

const SubText = styled(Typography)({
  fontSize: '0.8rem',
  color: '#666',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
});

const LabelIconContainer = styled('span')({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
});

const FilterPage = () => {
  const [checkedItems, setCheckedItems] = useState({
    guaranteed: false,
    tradeAssurance: false,
    verifiedSupplier: false,
    verifiedProSupplier: false,
    responseTime: false,
    mergeResults: false,
  });

  const handleCheckboxChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <StyledRoot>
      {/* Filter Header */}
      <SectionTitle>Filters</SectionTitle>

      {/* Guaranteed Filter */}
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedItems.guaranteed}
            onChange={handleCheckboxChange}
            name="guaranteed"
            color="primary"
          />
        }
        label={
          <LabelIconContainer>
            Switches 1 <InfoIcon fontSize="small" color="disabled" />
          </LabelIconContainer>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedItems.guaranteed}
            onChange={handleCheckboxChange}
            name="guaranteed"
            color="primary"
          />
        }
        label={
          <LabelIconContainer>
            Switches 2 <InfoIcon fontSize="small" color="disabled" />
          </LabelIconContainer>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedItems.guaranteed}
            onChange={handleCheckboxChange}
            name="guaranteed"
            color="primary"
          />
        }
        label={
          <LabelIconContainer>
            socket 1  <InfoIcon fontSize="small" color="disabled" />
          </LabelIconContainer>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedItems.guaranteed}
            onChange={handleCheckboxChange}
            name="guaranteed"
            color="primary"
          />
        }
        label={
          <LabelIconContainer>
            socket 2 <InfoIcon fontSize="small" color="disabled" />
          </LabelIconContainer>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedItems.guaranteed}
            onChange={handleCheckboxChange}
            name="guaranteed"
            color="primary"
          />
        }
        label={
          <LabelIconContainer>
            Switches 3 <InfoIcon fontSize="small" color="disabled" />
          </LabelIconContainer>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedItems.guaranteed}
            onChange={handleCheckboxChange}
            name="guaranteed"
            color="primary"
          />
        }
        label={
          <LabelIconContainer>
            socket 3 <InfoIcon fontSize="small" color="disabled" />
          </LabelIconContainer>
        }
      />

      {/* Trade Assurance */}
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedItems.tradeAssurance}
            onChange={handleCheckboxChange}
            name="tradeAssurance"
            color="primary"
          />
        }
        label={
          <LabelIconContainer>
            <VerifiedIcon fontSize="small" color="primary" />
            Trade Assurance
          </LabelIconContainer>
        }
      />
      <SubText>Protects your orders on Alibaba.com</SubText>

      {/* Supplier Features */}
      <SectionTitle>Supplier features</SectionTitle>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedItems.verifiedSupplier}
            onChange={handleCheckboxChange}
            name="verifiedSupplier"
            color="primary"
          />
        }
        label={
          <LabelIconContainer>
            <VerifiedIcon fontSize="small" color="primary" />
            Verified Supplier
            <InfoIcon fontSize="small" color="disabled" />
          </LabelIconContainer>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedItems.verifiedProSupplier}
            onChange={handleCheckboxChange}
            name="verifiedProSupplier"
            color="primary"
          />
        }
        label={
          <LabelIconContainer>
            <VerifiedIcon fontSize="small" color="primary" />
            Verified PRO Supplier
            <InfoIcon fontSize="small" color="disabled" />
          </LabelIconContainer>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedItems.responseTime}
            onChange={handleCheckboxChange}
            name="responseTime"
            color="primary"
          />
        }
        label="≤1h response time"
      />

      {/* Delivery By */}
      <SectionTitle>Delivery by</SectionTitle>
      <SubText>Unit price is subject to expected delivery date</SubText>
      <RadioGroup name="deliveryDate">
        <FormControlLabel
          control={<Radio />}
          label="Delivery by Nov 24"
          value="Nov 24"
        />
        <FormControlLabel
          control={<Radio />}
          label="Delivery by Nov 30"
          value="Nov 30"
        />
        <FormControlLabel
          control={<Radio />}
          label="Delivery by Dec 06"
          value="Dec 06"
        />
      </RadioGroup>

      {/* Merge Results */}
      <SectionTitle>Merge results</SectionTitle>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedItems.mergeResults}
            onChange={handleCheckboxChange}
            name="mergeResults"
            color="primary"
          />
        }
        label={
          <LabelIconContainer>
            Merge by supplier <NewReleasesIcon fontSize="small" color="primary" />
          </LabelIconContainer>
        }
      />
      <SubText>Only the most relevant item from each supplier will be shown</SubText>
    </StyledRoot>
  );
};

export default FilterPage;

import styled from '@emotion/styled';
import { useState } from 'react';
import { CategoryRounded, MenuOutlined } from '@mui/icons-material';
import { Typography, Popover, MenuItem, MenuList, IconButton,Box } from '@mui/material';
import Badge from '@mui/material/Badge';
// routes
import { PATH_AUTH, PATH_PAGE } from '../../../routes/paths';
// config
import { PATH_AFTER_LOGIN } from '../../../config';


// ----------------------------------------------------------------------
const StyledTypography = styled(Typography)(({ theme }) => ({
  color: 'white',
  padding: '5px',
  fontSize: '15px',
  '&:hover': {
    textDecoration: 'underline',
    textDecorationColor: 'red',
    textDecorationThickness: '2px',
  },
}));

const CategoriesNav = [


  {
    title: (
      <StyledTypography sx={{ display: 'flex' }}>
        <MenuOutlined />
        All
      </StyledTypography>
    ),
    path: '',

    children: [
      {
        subheader: 'Other',
        items: [
          { title: 'Construction Tools', path: PATH_PAGE.about },
          { title: 'Building Materials', path: PATH_PAGE.contact },
          { title: 'Concrete Blocks', path: PATH_PAGE.faqs },
          { title: 'Bricks', path: PATH_PAGE.pricing },
          { title: 'Steel', path: PATH_PAGE.payment },
          { title: 'Plywood', path: PATH_PAGE.maintenance },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Aggregates', path: PATH_AUTH.loginUnprotected },
          { title: 'Doors & Windows', path: PATH_AUTH.registerUnprotected },
          { title: 'Roofing Sheets', path: PATH_AUTH.resetPassword },
          { title: 'Insulation', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Scaffolding', path: PATH_AUTH.loginUnprotected },
          { title: 'Pipes & Fittings', path: PATH_AUTH.verify },
        ],
      },
    ],
  },
  {
    title: <StyledTypography>Construction</StyledTypography>,
    path: '',

    children: [
      {
        subheader: 'Other',
        items: [
          { title: 'Construction Tools', path: PATH_PAGE.about },
          { title: 'Building Materials', path: PATH_PAGE.contact },
          { title: 'Concrete Blocks', path: PATH_PAGE.faqs },
          { title: 'Bricks', path: PATH_PAGE.pricing },
          { title: 'Steel', path: PATH_PAGE.payment },
          { title: 'Plywood', path: PATH_PAGE.maintenance },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Aggregates', path: PATH_AUTH.loginUnprotected },
          { title: 'Doors & Windows', path: PATH_AUTH.registerUnprotected },
          { title: 'Roofing Sheets', path: PATH_AUTH.resetPassword },
          { title: 'Insulation', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Scaffolding', path: PATH_AUTH.loginUnprotected },
          { title: 'Pipes & Fittings', path: PATH_AUTH.verify },
        ],
      },
    ],
  },
  {
    title: <StyledTypography>Electrical</StyledTypography>,
    path: '',

    children: [
      {
        subheader: 'Other',
        items: [
          { title: 'Wires & Cables', path: PATH_PAGE.about },
          { title: 'Switches & Sockets', path: PATH_PAGE.contact },
          { title: 'Circuit Breakers', path: PATH_PAGE.faqs },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Distribution Boards', path: PATH_PAGE.pricing },
          { title: 'Conduits & Fittings', path: PATH_PAGE.payment },
          { title: 'Lighting Fixtures', path: PATH_AUTH.loginUnprotected },
          { title: 'Fans', path: PATH_AUTH.registerUnprotected },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Generators', path: PATH_AUTH.loginUnprotected },
          { title: 'Inverters', path: PATH_AUTH.registerUnprotected },
          { title: 'Solar Panels', path: PATH_AUTH.resetPassword },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Batteries', path: PATH_PAGE.page403 },
          { title: 'Transformers', path: PATH_PAGE.page404 },
          { title: 'Switchgear', path: PATH_PAGE.page500 },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Electric Motors', path: PATH_PAGE.page403 },
          { title: 'Control Panels', path: PATH_PAGE.page500 },
        ],
      },
    ],
  },
  {
    title: <StyledTypography>Paints</StyledTypography>,
    path: '',

    children: [
      {
        subheader: 'Other',
        items: [
          { title: 'About us', path: PATH_PAGE.about },
          { title: 'Contact us', path: PATH_PAGE.contact },
          { title: 'FAQs', path: PATH_PAGE.faqs },
          { title: 'Pricing', path: PATH_PAGE.pricing },
          { title: 'Payment', path: PATH_PAGE.payment },
          { title: 'Maintenance', path: PATH_PAGE.maintenance },
          { title: 'Coming Soon', path: PATH_PAGE.comingSoon },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Login', path: PATH_AUTH.loginUnprotected },
          { title: 'Register', path: PATH_AUTH.registerUnprotected },
          { title: 'Reset password', path: PATH_AUTH.resetPassword },
          { title: 'Verify code', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Login', path: PATH_AUTH.loginUnprotected },
          { title: 'Register', path: PATH_AUTH.registerUnprotected },
          { title: 'Reset password', path: PATH_AUTH.resetPassword },
          { title: 'Verify code', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Page 403', path: PATH_PAGE.page403 },
          { title: 'Page 404', path: PATH_PAGE.page404 },
          { title: 'Page 500', path: PATH_PAGE.page500 },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Page 403', path: PATH_PAGE.page403 },
          { title: 'Page 404', path: PATH_PAGE.page404 },
          { title: 'Page 500', path: PATH_PAGE.page500 },
        ],
      },
    ],
  },
  {
    title: <StyledTypography>Tiles and Marbles</StyledTypography>,
    path: '',

    children: [
      {
        subheader: 'Other',
        items: [
          { title: 'About us', path: PATH_PAGE.about },
          { title: 'Contact us', path: PATH_PAGE.contact },
          { title: 'FAQs', path: PATH_PAGE.faqs },
          { title: 'Pricing', path: PATH_PAGE.pricing },
          { title: 'Payment', path: PATH_PAGE.payment },
          { title: 'Maintenance', path: PATH_PAGE.maintenance },
          { title: 'Coming Soon', path: PATH_PAGE.comingSoon },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Login', path: PATH_AUTH.loginUnprotected },
          { title: 'Register', path: PATH_AUTH.registerUnprotected },
          { title: 'Reset password', path: PATH_AUTH.resetPassword },
          { title: 'Verify code', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Login', path: PATH_AUTH.loginUnprotected },
          { title: 'Register', path: PATH_AUTH.registerUnprotected },
          { title: 'Reset password', path: PATH_AUTH.resetPassword },
          { title: 'Verify code', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Page 403', path: PATH_PAGE.page403 },
          { title: 'Page 404', path: PATH_PAGE.page404 },
          { title: 'Page 500', path: PATH_PAGE.page500 },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Page 403', path: PATH_PAGE.page403 },
          { title: 'Page 404', path: PATH_PAGE.page404 },
          { title: 'Page 500', path: PATH_PAGE.page500 },
        ],
      },
    ],
  },
  {
    title: <StyledTypography>Sand</StyledTypography>,
    path: '',

    children: [
      {
        subheader: 'Other',
        items: [
          { title: 'About us', path: PATH_PAGE.about },
          { title: 'Contact us', path: PATH_PAGE.contact },
          { title: 'FAQs', path: PATH_PAGE.faqs },
          { title: 'Pricing', path: PATH_PAGE.pricing },
          { title: 'Payment', path: PATH_PAGE.payment },
          { title: 'Maintenance', path: PATH_PAGE.maintenance },
          { title: 'Coming Soon', path: PATH_PAGE.comingSoon },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Login', path: PATH_AUTH.loginUnprotected },
          { title: 'Register', path: PATH_AUTH.registerUnprotected },
          { title: 'Reset password', path: PATH_AUTH.resetPassword },
          { title: 'Verify code', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Login', path: PATH_AUTH.loginUnprotected },
          { title: 'Register', path: PATH_AUTH.registerUnprotected },
          { title: 'Reset password', path: PATH_AUTH.resetPassword },
          { title: 'Verify code', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Page 403', path: PATH_PAGE.page403 },
          { title: 'Page 404', path: PATH_PAGE.page404 },
          { title: 'Page 500', path: PATH_PAGE.page500 },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Page 403', path: PATH_PAGE.page403 },
          { title: 'Page 404', path: PATH_PAGE.page404 },
          { title: 'Page 500', path: PATH_PAGE.page500 },
        ],
      },
    ],
  },
  {
    title: <StyledTypography>Cement</StyledTypography>,
    path: '',

    children: [
      {
        subheader: 'Other',
        items: [
          { title: 'About us', path: PATH_PAGE.about },
          { title: 'Contact us', path: PATH_PAGE.contact },
          { title: 'FAQs', path: PATH_PAGE.faqs },
          { title: 'Pricing', path: PATH_PAGE.pricing },
          { title: 'Payment', path: PATH_PAGE.payment },
          { title: 'Maintenance', path: PATH_PAGE.maintenance },
          { title: 'Coming Soon', path: PATH_PAGE.comingSoon },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Login', path: PATH_AUTH.loginUnprotected },
          { title: 'Register', path: PATH_AUTH.registerUnprotected },
          { title: 'Reset password', path: PATH_AUTH.resetPassword },
          { title: 'Verify code', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Login', path: PATH_AUTH.loginUnprotected },
          { title: 'Register', path: PATH_AUTH.registerUnprotected },
          { title: 'Reset password', path: PATH_AUTH.resetPassword },
          { title: 'Verify code', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Page 403', path: PATH_PAGE.page403 },
          { title: 'Page 404', path: PATH_PAGE.page404 },
          { title: 'Page 500', path: PATH_PAGE.page500 },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Page 403', path: PATH_PAGE.page403 },
          { title: 'Page 404', path: PATH_PAGE.page404 },
          { title: 'Page 500', path: PATH_PAGE.page500 },
        ],
      },
    ],
  },
  {
    title: <StyledTypography>Paints</StyledTypography>,
    path: '',

    children: [
      {
        subheader: 'Other',
        items: [
          { title: 'About us', path: PATH_PAGE.about },
          { title: 'Contact us', path: PATH_PAGE.contact },
          { title: 'FAQs', path: PATH_PAGE.faqs },
          { title: 'Pricing', path: PATH_PAGE.pricing },
          { title: 'Payment', path: PATH_PAGE.payment },
          { title: 'Maintenance', path: PATH_PAGE.maintenance },
          { title: 'Coming Soon', path: PATH_PAGE.comingSoon },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Login', path: PATH_AUTH.loginUnprotected },
          { title: 'Register', path: PATH_AUTH.registerUnprotected },
          { title: 'Reset password', path: PATH_AUTH.resetPassword },
          { title: 'Verify code', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Login', path: PATH_AUTH.loginUnprotected },
          { title: 'Register', path: PATH_AUTH.registerUnprotected },
          { title: 'Reset password', path: PATH_AUTH.resetPassword },
          { title: 'Verify code', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Page 403', path: PATH_PAGE.page403 },
          { title: 'Page 404', path: PATH_PAGE.page404 },
          { title: 'Page 500', path: PATH_PAGE.page500 },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Page 403', path: PATH_PAGE.page403 },
          { title: 'Page 404', path: PATH_PAGE.page404 },
          { title: 'Page 500', path: PATH_PAGE.page500 },
        ],
      },
    ],
  },
  {
    title: <StyledTypography>Construction</StyledTypography>,
    path: '',

    children: [
      {
        subheader: 'Other',
        items: [
          { title: 'Construction Tools', path: PATH_PAGE.about },
          { title: 'Building Materials', path: PATH_PAGE.contact },
          { title: 'Concrete Blocks', path: PATH_PAGE.faqs },
          { title: 'Bricks', path: PATH_PAGE.pricing },
          { title: 'Steel', path: PATH_PAGE.payment },
          { title: 'Plywood', path: PATH_PAGE.maintenance },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Aggregates', path: PATH_AUTH.loginUnprotected },
          { title: 'Doors & Windows', path: PATH_AUTH.registerUnprotected },
          { title: 'Roofing Sheets', path: PATH_AUTH.resetPassword },
          { title: 'Insulation', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Scaffolding', path: PATH_AUTH.loginUnprotected },
          { title: 'Pipes & Fittings', path: PATH_AUTH.verify },
        ],
      },
    ],
  },
  {
    title: <StyledTypography>Electrical</StyledTypography>,
    path: '',

    children: [
      {
        subheader: 'Other',
        items: [
          { title: 'Wires & Cables', path: PATH_PAGE.about },
          { title: 'Switches & Sockets', path: PATH_PAGE.contact },
          { title: 'Circuit Breakers', path: PATH_PAGE.faqs },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Distribution Boards', path: PATH_PAGE.pricing },
          { title: 'Conduits & Fittings', path: PATH_PAGE.payment },
          { title: 'Lighting Fixtures', path: PATH_AUTH.loginUnprotected },
          { title: 'Fans', path: PATH_AUTH.registerUnprotected },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Generators', path: PATH_AUTH.loginUnprotected },
          { title: 'Inverters', path: PATH_AUTH.registerUnprotected },
          { title: 'Solar Panels', path: PATH_AUTH.resetPassword },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Batteries', path: PATH_PAGE.page403 },
          { title: 'Transformers', path: PATH_PAGE.page404 },
          { title: 'Switchgear', path: PATH_PAGE.page500 },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Electric Motors', path: PATH_PAGE.page403 },
          { title: 'Control Panels', path: PATH_PAGE.page500 },
        ],
      },
    ],
  },
  {
    title: <StyledTypography>Paints</StyledTypography>,
    path: '',

    children: [
      {
        subheader: 'Other',
        items: [
          { title: 'About us', path: PATH_PAGE.about },
          { title: 'Contact us', path: PATH_PAGE.contact },
          { title: 'FAQs', path: PATH_PAGE.faqs },
          { title: 'Pricing', path: PATH_PAGE.pricing },
          { title: 'Payment', path: PATH_PAGE.payment },
          { title: 'Maintenance', path: PATH_PAGE.maintenance },
          { title: 'Coming Soon', path: PATH_PAGE.comingSoon },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Login', path: PATH_AUTH.loginUnprotected },
          { title: 'Register', path: PATH_AUTH.registerUnprotected },
          { title: 'Reset password', path: PATH_AUTH.resetPassword },
          { title: 'Verify code', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Login', path: PATH_AUTH.loginUnprotected },
          { title: 'Register', path: PATH_AUTH.registerUnprotected },
          { title: 'Reset password', path: PATH_AUTH.resetPassword },
          { title: 'Verify code', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Page 403', path: PATH_PAGE.page403 },
          { title: 'Page 404', path: PATH_PAGE.page404 },
          { title: 'Page 500', path: PATH_PAGE.page500 },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Page 403', path: PATH_PAGE.page403 },
          { title: 'Page 404', path: PATH_PAGE.page404 },
          { title: 'Page 500', path: PATH_PAGE.page500 },
        ],
      },
    ],
  },
  {
    title: <StyledTypography>Tiles and Marbles</StyledTypography>,
    path: '',

    children: [
      {
        subheader: 'Other',
        items: [
          { title: 'About us', path: PATH_PAGE.about },
          { title: 'Contact us', path: PATH_PAGE.contact },
          { title: 'FAQs', path: PATH_PAGE.faqs },
          { title: 'Pricing', path: PATH_PAGE.pricing },
          { title: 'Payment', path: PATH_PAGE.payment },
          { title: 'Maintenance', path: PATH_PAGE.maintenance },
          { title: 'Coming Soon', path: PATH_PAGE.comingSoon },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Login', path: PATH_AUTH.loginUnprotected },
          { title: 'Register', path: PATH_AUTH.registerUnprotected },
          { title: 'Reset password', path: PATH_AUTH.resetPassword },
          { title: 'Verify code', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Login', path: PATH_AUTH.loginUnprotected },
          { title: 'Register', path: PATH_AUTH.registerUnprotected },
          { title: 'Reset password', path: PATH_AUTH.resetPassword },
          { title: 'Verify code', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Page 403', path: PATH_PAGE.page403 },
          { title: 'Page 404', path: PATH_PAGE.page404 },
          { title: 'Page 500', path: PATH_PAGE.page500 },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Page 403', path: PATH_PAGE.page403 },
          { title: 'Page 404', path: PATH_PAGE.page404 },
          { title: 'Page 500', path: PATH_PAGE.page500 },
        ],
      },
    ],
  },
  {
    title: <StyledTypography>Construction</StyledTypography>,
    path: '',

    children: [
      {
        subheader: 'Other',
        items: [
          { title: 'Construction Tools', path: PATH_PAGE.about },
          { title: 'Building Materials', path: PATH_PAGE.contact },
          { title: 'Concrete Blocks', path: PATH_PAGE.faqs },
          { title: 'Bricks', path: PATH_PAGE.pricing },
          { title: 'Steel', path: PATH_PAGE.payment },
          { title: 'Plywood', path: PATH_PAGE.maintenance },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Aggregates', path: PATH_AUTH.loginUnprotected },
          { title: 'Doors & Windows', path: PATH_AUTH.registerUnprotected },
          { title: 'Roofing Sheets', path: PATH_AUTH.resetPassword },
          { title: 'Insulation', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Scaffolding', path: PATH_AUTH.loginUnprotected },
          { title: 'Pipes & Fittings', path: PATH_AUTH.verify },
        ],
      },
    ],
  },
  {
    title: <StyledTypography>Electrical</StyledTypography>,
    path: '',

    children: [
      {
        subheader: 'Other',
        items: [
          { title: 'Wires & Cables', path: PATH_PAGE.about },
          { title: 'Switches & Sockets', path: PATH_PAGE.contact },
          { title: 'Circuit Breakers', path: PATH_PAGE.faqs },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Distribution Boards', path: PATH_PAGE.pricing },
          { title: 'Conduits & Fittings', path: PATH_PAGE.payment },
          { title: 'Lighting Fixtures', path: PATH_AUTH.loginUnprotected },
          { title: 'Fans', path: PATH_AUTH.registerUnprotected },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Generators', path: PATH_AUTH.loginUnprotected },
          { title: 'Inverters', path: PATH_AUTH.registerUnprotected },
          { title: 'Solar Panels', path: PATH_AUTH.resetPassword },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Batteries', path: PATH_PAGE.page403 },
          { title: 'Transformers', path: PATH_PAGE.page404 },
          { title: 'Switchgear', path: PATH_PAGE.page500 },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Electric Motors', path: PATH_PAGE.page403 },
          { title: 'Control Panels', path: PATH_PAGE.page500 },
        ],
      },
    ],
  },
  {
    title: <StyledTypography>Paints</StyledTypography>,
    path: '',

    children: [
      {
        subheader: 'Other',
        items: [
          { title: 'About us', path: PATH_PAGE.about },
          { title: 'Contact us', path: PATH_PAGE.contact },
          { title: 'FAQs', path: PATH_PAGE.faqs },
          { title: 'Pricing', path: PATH_PAGE.pricing },
          { title: 'Payment', path: PATH_PAGE.payment },
          { title: 'Maintenance', path: PATH_PAGE.maintenance },
          { title: 'Coming Soon', path: PATH_PAGE.comingSoon },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Login', path: PATH_AUTH.loginUnprotected },
          { title: 'Register', path: PATH_AUTH.registerUnprotected },
          { title: 'Reset password', path: PATH_AUTH.resetPassword },
          { title: 'Verify code', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Login', path: PATH_AUTH.loginUnprotected },
          { title: 'Register', path: PATH_AUTH.registerUnprotected },
          { title: 'Reset password', path: PATH_AUTH.resetPassword },
          { title: 'Verify code', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Page 403', path: PATH_PAGE.page403 },
          { title: 'Page 404', path: PATH_PAGE.page404 },
          { title: 'Page 500', path: PATH_PAGE.page500 },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Page 403', path: PATH_PAGE.page403 },
          { title: 'Page 404', path: PATH_PAGE.page404 },
          { title: 'Page 500', path: PATH_PAGE.page500 },
        ],
      },
    ],
  },
  {
    title: <StyledTypography>Tiles and Marbles</StyledTypography>,
    path: '',

    children: [
      {
        subheader: 'Other',
        items: [
          { title: 'About us', path: PATH_PAGE.about },
          { title: 'Contact us', path: PATH_PAGE.contact },
          { title: 'FAQs', path: PATH_PAGE.faqs },
          { title: 'Pricing', path: PATH_PAGE.pricing },
          { title: 'Payment', path: PATH_PAGE.payment },
          { title: 'Maintenance', path: PATH_PAGE.maintenance },
          { title: 'Coming Soon', path: PATH_PAGE.comingSoon },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Login', path: PATH_AUTH.loginUnprotected },
          { title: 'Register', path: PATH_AUTH.registerUnprotected },
          { title: 'Reset password', path: PATH_AUTH.resetPassword },
          { title: 'Verify code', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Authentication',
        items: [
          { title: 'Login', path: PATH_AUTH.loginUnprotected },
          { title: 'Register', path: PATH_AUTH.registerUnprotected },
          { title: 'Reset password', path: PATH_AUTH.resetPassword },
          { title: 'Verify code', path: PATH_AUTH.verify },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Page 403', path: PATH_PAGE.page403 },
          { title: 'Page 404', path: PATH_PAGE.page404 },
          { title: 'Page 500', path: PATH_PAGE.page500 },
        ],
      },
      {
        subheader: 'Error',
        items: [
          { title: 'Page 403', path: PATH_PAGE.page403 },
          { title: 'Page 404', path: PATH_PAGE.page404 },
          { title: 'Page 500', path: PATH_PAGE.page500 },
        ],
      },
    ],
  },
];


 export default CategoriesNav;











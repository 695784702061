import React from 'react';
import { Box, Grid, Typography, Link } from '@mui/material';
import { styled } from '@mui/system';

// Placeholder images for payment methods and app store buttons
const paymentIcons = [
    '/path/to/icon1.png', '/path/to/icon2.png', '/path/to/icon3.png', '/path/to/icon4.png', 
    '/path/to/icon5.png', '/path/to/icon6.png', '/path/to/icon7.png'
];
const socialIcons = [
    '/path/to/facebook-icon.png', '/path/to/linkedin-icon.png', '/path/to/twitter-icon.png', 
    '/path/to/instagram-icon.png', '/path/to/youtube-icon.png', '/path/to/tiktok-icon.png'
];

// Styled components for styling and alignment
const FooterContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(4, 2),
}));

const FooterSection = styled(Grid)({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginBottom: '16px',
});

const PaymentIconsContainer = styled(Box)({
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '16px',
});

const SocialIconsContainer = styled(Box)({
    display: 'flex',
    gap: '12px',
    justifyContent: 'center',
    marginTop: '16px',
});

const FooterLink = styled(Link)({
    color: '#000',
    textDecoration: 'none',
    fontSize: '14px',
    '&:hover': {
        textDecoration: 'underline',
    },
});

const Footer = () => {
    return (
        <FooterContainer>
            <Grid container spacing={3} justifyContent="center">
                {/* Section 1: Get Support */}
                <Grid item xs={12} md={2}>
                    <FooterSection>
                        <Typography variant="h6">Get support</Typography>
                        <FooterLink href="#">Help Center</FooterLink>
                        <FooterLink href="#">Live chat</FooterLink>
                        <FooterLink href="#">Check order status</FooterLink>
                        <FooterLink href="#">Refunds</FooterLink>
                        <FooterLink href="#">Report abuse</FooterLink>
                    </FooterSection>
                </Grid>
                
                {/* Section 2: Payments and protections */}
                <Grid item xs={12} md={2}>
                    <FooterSection>
                        <Typography variant="h6">Payments and protections</Typography>
                        <FooterLink href="#">Safe and easy payments</FooterLink>
                        <FooterLink href="#">OneRoof.com Business Edge Credit Card</FooterLink>
                        <FooterLink href="#">Money-back policy</FooterLink>
                        <FooterLink href="#">On-time shipping</FooterLink>
                        <FooterLink href="#">After-sales protections</FooterLink>
                        <FooterLink href="#">Product monitoring services</FooterLink>
                    </FooterSection>
                </Grid>
                
                {/* Section 3: Source on OneRoof.com */}
                <Grid item xs={12} md={2}>
                    <FooterSection>
                        <Typography variant="h6">Source on OneRoof.com</Typography>
                        <FooterLink href="#">Request for Quotation</FooterLink>
                        <FooterLink href="#">Membership program</FooterLink>
                        <FooterLink href="#">OneRoof.com Logistics</FooterLink>
                        <FooterLink href="#">Sales tax and VAT</FooterLink>
                        <FooterLink href="#">OneRoof.com Reads</FooterLink>
                    </FooterSection>
                </Grid>
                
                {/* Section 4: Sell on OneRoof.com */}
                <Grid item xs={12} md={2}>
                    <FooterSection>
                        <Typography variant="h6">Sell on OneRoof.com</Typography>
                        <FooterLink href="#">Start selling</FooterLink>
                        <FooterLink href="#">Seller Central</FooterLink>
                        <FooterLink href="#">Become a Verified Supplier</FooterLink>
                        <FooterLink href="#">Partnerships</FooterLink>
                        <FooterLink href="#">Download the app for suppliers</FooterLink>
                    </FooterSection>
                </Grid>
                
                {/* Section 5: Get to know us */}
                <Grid item xs={12} md={2}>
                    <FooterSection>
                        <Typography variant="h6">Get to know us</Typography>
                        <FooterLink href="#">About OneRoof.com</FooterLink>
                        <FooterLink href="#">Corporate responsibility</FooterLink>
                        <FooterLink href="#">News center</FooterLink>
                        <FooterLink href="#">Careers</FooterLink>
                    </FooterSection>
                </Grid>
            </Grid>


            {/* App Download Links */}
            <Box textAlign="center" marginTop={2}>
                <Typography variant="body2">
                    Trade on the go with the <FooterLink href="#">OneRoof.com app</FooterLink>
                </Typography>
        
            </Box>
        </FooterContainer>
    );
};

export default Footer;

import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Typography, IconButton, Button, TextField, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const StyledRoot = styled('div')({
  padding: '27px',
  width: '100%',
  maxWidth: '615px',
  height: 'calc(100vh - 50%)',
  overflowY: 'auto',
  backgroundColor: '#fff',
  border: '1px solid #e0e0e0',
  borderRadius: '4px',
  zIndex: '200px',
  scrollSnapType: 'y mandatory',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

const Cylinder = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '170px',
  height: '40px',
  borderRadius: '40px',
  border: '2px solid #e0e0e0',
  padding: '0 5px',
});

const Button1 = styled(Button)({
  height: '55px',
  width: '49%',
  padding: '10px',
  color: '#fff',
  backgroundColor: '#FF5733',
  borderRadius: '100px',
  border: '2px solid #e0e0e0',
  '&:hover': {
    backgroundColor: '#ff4500',
  },
});

const Button2 = styled(Button)({
  height: '55px',
  width: '49%',
  color: 'black',
  padding: '10px',
  borderRadius: '100px',
  border: '2px solid #e0e0e0',
  '&:hover': {
    backgroundColor: '#fff',
  },
});

const BottomBar = styled('div')({
  position: 'fixed',
  bottom: '0',
  right: '0',
  width:"31%",
  backgroundColor: '#fff',
  padding: '25px',
  boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
  zIndex: 1000,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const Count = styled('div')({
  marginLeft: 'auto',
});

function CartDetails() {
  const [value, setValue] = useState(0);

  const handleIncrement = () => {
    setValue((prevValue) => prevValue + 1);
  };

  const handleDecrement = () => {
    if (value > 0) {
      setValue((prevValue) => prevValue - 1);
    }
  };

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    if (!Number.isNaN(newValue)) {
      setValue(newValue);
    }
  };

  const renderCartItem = (title, price) => (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2, mt: 2, flexWrap: 'wrap' }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        <img
          src="/assets/images/brick_type2.jpg"
          alt={title}
          style={{ width: '50px', height: '50px', marginRight: '10px' }}
        />
        <Typography sx={{ flex: '1', mr: 2 }}>{title}</Typography>
        <Typography sx={{ mr: 'auto' }}>${price}</Typography>
      </Box>
      <Count>
        <Cylinder>
          <IconButton size="small" onClick={handleDecrement}>
            <RemoveIcon />
          </IconButton>
          <TextField
            type="number"
            value={value}
            onChange={handleChange}
            inputProps={{ style: { textAlign: 'center', height: '4px', width: '50px' } }}
          />
          <IconButton size="small" onClick={handleIncrement}>
            <AddIcon />
          </IconButton>
        </Cylinder>
      </Count>
    </Box>
  );

  return (
    <>
      <StyledRoot>
        <Typography variant="h5">Select variations and Quantity</Typography>
        <Typography sx={{ mt: '15px', fontSize: '1.2rem' }}>Price before shipping</Typography>
        <Typography sx={{ mt: '15px', fontSize: '1.1rem' }}>Minimum order quantity: 3 sets</Typography>
        <Typography sx={{ mt: '15px' }}>Color</Typography>
        {renderCartItem('51 MCU loose parts', '170.00')}
        {renderCartItem('51 MCU loose parts', '170.00')}
        {renderCartItem('51 MCU loose parts', '170.00')}
        {renderCartItem('51 MCU loose parts', '170.00')}
        {renderCartItem('51 MCU loose parts', '170.00')}
        {renderCartItem('51 MCU loose parts', '170.00')}
        <BottomBar>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography>Item subtotal (0 variations 0 items)</Typography>
            <Typography>$0.00</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }}>
            <Typography>Shipping total</Typography>
            <Typography>$0.00</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }}>
            <Typography>Total</Typography>
            <Typography>$0.00</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }}>
            <Button1>Continue to Cart</Button1>
            <Button2>Add to cart</Button2>
          </Box>
        </BottomBar>
      </StyledRoot>
    </>
  );
}

export default CartDetails;

// import React, { useState } from 'react';
// import styled from '@emotion/styled';
// import {
//   Box,
//   Typography,
//   Paper,
//   IconButton,
//   Grid,
//   MenuItem,
//   Menu,
//   Button,
//   useMediaQuery,
//   useTheme,
// } from '@mui/material';
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
// import EditIcon from '@mui/icons-material/Edit';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import PrintIcon from '@mui/icons-material/Print';
// import DownloadIcon from '@mui/icons-material/Download';
// import ShareIcon from '@mui/icons-material/Share';
// import SendIcon from '@mui/icons-material/Send';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import logo from '../../assets/logo/logo_image.jpg';
// import ViewQuotations from './ViewQuotations';

// function Invoice() {
//   // const logo = `${process.env.PUBLIC_URL}/logo/logo_image.jpg`;

//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const open = Boolean(anchorEl);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleCloseMenu = () => {
//     setAnchorEl(null);
//   };

//   function createData(Id, DescriptionOfItems,  Totalamount) {
//     return { Id, DescriptionOfItems,  Totalamount };
//   }

//   const rows = [
//     createData(1, 'Burnt clay bricks',  100),
//     createData(2, 'Concrete bricks', 100),
//     createData(3, 'Fly ash bricks',  100),
//     createData(4, 'Fire bricks', 100),
//     createData(5, 'Sand-lime bricks',  100),

//   ];

//   const Container = styled(Paper)({
//     padding: '20px',
//     height: 'auto',

//     width: '130%',
//     maxWidth: '900px',

//     margin: '0 0 0 auto', // Centers the container
//     marginTop: '10px',
//     boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
//     borderRadius: '8px',
//     backgroundColor: '#f9f9f9',
//   });

//   const TableContainer = styled('div')({
//     padding: '10px',
//     marginTop: '50px',
//   });

//   const StyledTableCell = styled(TableCell)(({ theme }) => ({
//     [`&.${tableCellClasses.head}`]: {
//       backgroundColor: theme.palette.grey[300],
//       color: theme.palette.common.black,
//     },
//     [`&.${tableCellClasses.body}`]: {
//       fontSize: 14,
//     },
//   }));

//    const StyledTableRow = styled(TableRow)(({ theme }) => ({

//      '&:last-child td, &:last-child th': {
//        border: 1,
//      },
//      // Add a bottom border for all rows
//      '&:not(:last-child)': {
//        borderBottom: '1px solid #ddd', // Adjust the color as needed
//        marginBottom:"17px"
//      },
//    }));

//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//   return (
//     <>
//       <Container>
//         <Box
//           sx={{
//             display: 'flex',
//             flexDirection: isMobile ? 'column' : 'row',
//             justifyContent: 'space-between',
//             // backgroundColor: '#3f51b5',
//             borderRadius: '8px',
//             padding: '10px',
//             marginTop: '-17px',
//           }}
//         >
//           <Box sx={{ marginBottom: isMobile ? 2 : 0 }}>
//             <Typography variant="h3" sx={{ color: 'black' }}>
//               INVOICE
//             </Typography>
//           </Box>
//           <Box sx={{ textAlign: 'center', color: 'black' }}>
//             <div>
//               <Typography>Cygnosis</Typography>
//               <Typography>Ayyappa Society</Typography>
//               <Typography>Madhapur, Telangana, 500081</Typography>
//             </div>
//           </Box>
//         </Box>

//         <Grid container spacing={2} sx={{ mt: 1, padding: '10px' }}>
//           <Grid item xs={12} sm={6} sx={{ textAlign: isMobile ? 'center' : 'left' }}>
//             <Typography variant="h6">Ms. Sowmya</Typography>
//             <Typography>4507 Lake Floyd Circle</Typography>
//             <Typography>Madhapur, Telangana, 500081</Typography>
//             <Typography>Ayyappa Society</Typography>
//           </Grid>
//           <Grid item xs={12} sm={6} sx={{ textAlign: isMobile ? 'center' : 'right' }}>
//             <Typography variant="h6">Ship To</Typography>
//             <Typography variant="h6">Ms. Sowmya</Typography>
//             <Typography>4507 Lake Floyd Circle</Typography>
//             <Typography>Madhapur, Telangana, 500081</Typography>
//           </Grid>
//         </Grid>

//         <TableContainer component={Paper}>
//           <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
//             <TableHead>
//               <TableRow>
//                 <TableCell align="left">#</TableCell>
//                 <TableCell align="left">Item & Description</TableCell>
//                 <TableCell align="right">Amount</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {rows.map((row, index) => (
//                 <StyledTableRow key={index}>
//                   <TableCell component="th" scope="row" align="left">
//                     {row.Id}
//                   </TableCell>
//                   <TableCell align="left">{row.DescriptionOfItems}</TableCell>
//                   <TableCell align="right">{row.Totalamount}</TableCell>
//                 </StyledTableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//         <Box sx={{ marginTop: '20px' }}>
//           {/* <Grid item xs={8}>
//             <Typography>Invoice # - INV 123456</Typography>
//             <Typography>Invoice Date - 11-2-2024</Typography>
//             <Typography>Terms - Due on Receipt</Typography>
//             <Typography>Due Date - 05-Aug-2024</Typography>
//           </Grid> */}
//           {[
//             { label: 'Subtotal', amount: '345678' },
//             { label: 'Tax', amount: '345678' },
//             { label: 'Total', amount: '456789' },
//           ].map((item, index) => (
//             <Grid container spacing={6} key={index}>
//               <Grid item xs={8} sx={{ textAlign: 'right' }}>
//                 <Typography variant="body1">
//                   <strong>{item.label}</strong>
//                 </Typography>
//               </Grid>
//               <Grid item xs={4} sx={{ textAlign: 'right' }}>
//                 <Typography variant="body1">{item.amount}</Typography>
//               </Grid>
//             </Grid>
//           ))}
//         </Box>
//       </Container>
//     </>
//   );
// }

// export default Invoice;

import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  Box,
  Typography,
  Paper,
  IconButton,
  Grid,
  MenuItem,
  Menu,
  Button,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';
import ShareIcon from '@mui/icons-material/Share';
import SendIcon from '@mui/icons-material/Send';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import logo from '../../assets/logo/logo_image.jpg';
import ViewQuotations from './ViewQuotations';

function Invoice() {
  // const logo = `${process.env.PUBLIC_URL}/logo/logo_image.jpg`;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  function createData(Id, DescriptionOfItems, Totalamount) {
    return { Id, DescriptionOfItems, Totalamount };
  }

  const rows = [
    createData(1, 'Burnt clay bricks', 100),
    createData(2, 'Concrete bricks', 100),
    createData(3, 'Fly ash bricks', 100),
    createData(4, 'Fire bricks', 100),
    createData(5, 'Sand-lime bricks', 100),
  ];

  const Container = styled(Paper)({
    padding: '20px',
    height: 'auto',
    width: '100%',
    maxWidth: '950px',
    marginTop: '10px',
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
  });

  const TableContainer = styled('div')({
    padding: '10px',
    marginTop: '50px',
  });

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },

    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          send
        </Button>
        <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
          <MenuItem onClick={handleCloseMenu}>
            <ShareIcon sx={{ marginRight: 1 }} /> Share
          </MenuItem>
          <MenuItem onClick={handleCloseMenu}>
            <SendIcon sx={{ marginRight: 1 }} /> Send
          </MenuItem>
          <MenuItem onClick={handleCloseMenu}>
            <VisibilityIcon sx={{ marginRight: 1 }} /> View
          </MenuItem>
          <MenuItem onClick={handleCloseMenu}>
            <PrintIcon sx={{ marginRight: 1 }} /> Print
          </MenuItem>
          <MenuItem onClick={handleCloseMenu}>
            <DownloadIcon sx={{ marginRight: 1 }} /> Download
          </MenuItem>
        </Menu>
      </Box>
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
            // backgroundColor: '#3f51b5',
            borderRadius: '8px',
            padding: '10px',
            marginTop: '-17px',
          }}
        >
          <Box sx={{ marginBottom: isMobile ? 2 : 0 }}>
            <img src={logo} alt="Logo" style={{ width: isMobile ? '80px' : '100px', marginBottom: '8px' }} />
          </Box>
          <Box sx={{ textAlign: 'center', color: 'black' }}>
            <div>
              <Typography>1Roof</Typography>
              <Typography>Ayyappa Society</Typography>
              <Typography>Madhapur, Telangana, 500081</Typography>
            </div>
          </Box>
        </Box>

        <Typography variant="h4" sx={{ color: 'black', textAlign: 'center', mb: '20px' }}>
          INVOICE
        </Typography>

        <Grid container spacing={4} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6} sx={{ textAlign: isMobile ? 'center' : 'left' }}>
            <Typography variant="subtitle1" fontWeight="bold">
              To
            </Typography>
            <Typography variant="body2">Ms.Sowmya</Typography>
            <Typography variant="body2">123 Business Street</Typography>
            <Typography variant="body2">City, State, ZIP</Typography>
            <Typography variant="body2">Email: contact@company.com</Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ textAlign: isMobile ? 'center' : 'right' }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Invoice Details
            </Typography>
            <Typography variant="body2">Invoice Id: #1234</Typography>
            <Typography variant="body2">Date:12-2-2024</Typography>
            <Typography variant="body2">Due Date:12-2-2024</Typography>
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left">#</TableCell>
                <TableCell align="left">Item & Description</TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow key={index}>
                  <TableCell component="th" scope="row" align="left">
                    {row.Id}
                  </TableCell>
                  <TableCell align="left">{row.DescriptionOfItems}</TableCell>
                  <TableCell align="right">{row.Totalamount}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box >
          <Grid xs={12} sx={{  }}>
            <Typography>Thanks for your Business!!</Typography>
          </Grid>
          <Grid>
            {[
              { label: 'Subtotal', amount: '3450' },
              { label: 'Discount', amount: '30%' },
              { label: 'Shipping charge', amount: '348' },
              { label: 'Tax', amount: '3%' },
              { label: 'Total', amount: '4567' },
            ].map((item, index) => (
              <Grid container spacing={6} key={index}>
                <Grid item xs={8} sx={{ textAlign: 'right' }}>
                  <Typography variant="body1">
                    <strong>{item.label}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: 'right' }}>
                  <Typography variant="body1">{item.amount}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default Invoice;

import { useState } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Box, Divider, Drawer, Stack, Typography, IconButton,Badge } from '@mui/material';
import { SettingsSuggest, Filter, ShoppingCart } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CartDetails from '../../../pages/cart/CartDetails';
import FilterPage from '../../../pages/RtlComponents/FilterPage';
import { NAV } from '../../../config';
import Iconify from '../../iconify';
import { defaultSettings } from '../config';
import { useSettingsContext } from '../SettingsContext';

import AccountPopover from '../../../pages/profile/AccountPopover';
import Quotation from '../../../pages/profile/Quotation';

const SPACING = 2.5;

FilterDrawer.propTypes = {
  filter: PropTypes.bool,
  cart: PropTypes.bool,
  profile: PropTypes.bool,
  suggestion: PropTypes.bool,
  Quotations: PropTypes.bool,
};

export default function FilterDrawer({ filter, cart, profile, suggestion, Quotations }) {
  const { themeMode, themeLayout, themeStretch, themeContrast, themeDirection, themeColorPresets, onResetSetting } =
    useSettingsContext();

  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const notDefault =
    themeMode !== defaultSettings.themeMode ||
    themeLayout !== defaultSettings.themeLayout ||
    themeStretch !== defaultSettings.themeStretch ||
    themeContrast !== defaultSettings.themeContrast ||
    themeDirection !== defaultSettings.themeDirection ||
    themeColorPresets !== defaultSettings.themeColorPresets;

  return (
    <>
      {/* Toggle button to open drawer */}
      {/*              
            <Button variant="contained" onClick={handleToggle}>
                {filter ? 'Filters' : <SettingsSuggest /> } 
            </Button> */}

      {filter && (
        <Box
          variant="contained"
          onClick={handleToggle}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Typography>Filter</Typography>
          <Filter />
        </Box>
      )}

      {suggestion && <SettingsSuggest variant="contained" onClick={handleToggle} />}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {profile && <Avatar alt="Sowmya" src="/assets/images/portraits/portrait_1.jpg" onClick={handleToggle} />}
        {cart && 
        <Badge badgeContent={1} color="error">
        <ShoppingCart sx={{ fontSize: '35px' }} onClick={handleToggle} />
         </Badge>     } 
      </Box>

      {Quotations && <VisibilityIcon variant="contained" onClick={handleToggle} />}

      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        BackdropProps={{ invisible: true }}
        PaperProps={{
          sx: {
            backgroundColor: Quotations ? 'white' : profile ? 'white' : alpha(theme.palette.background.default, 0.9),
            width: cart ? NAV.W_BASE1 : profile ? NAV.W_BASE1 : Quotations ? NAV.W_BASE1 : NAV.W_BASE2,

            boxShadow: `-24px 12px 40px 0 ${alpha(
              theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
              0.16
            )}`,
          },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2, pr: 1, pl: SPACING }}>
          <Typography variant="h6">
            {filter
              ? 'Filter Options'
              : cart
              ? 'Cart Details'
              : profile
              ? 'Profile'
              : Quotations
              ? 'Quotation'
              : 'Settings'}
          </Typography>
          <IconButton onClick={handleClose}>
            <Iconify icon="eva:close-fill" width={24} height={24} />
          </IconButton>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed', mb: 1 }} />

        <Box sx={{ px: 2 }}>
          {filter ? (
            <FilterPage />
          ) : cart ? (
            <CartDetails />
          ) : profile ? (
            <AccountPopover />
          ) : Quotations ? (
            <Quotation />
          ) : (
            'Settings'
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed', my: 2 }} />
      </Drawer>
    </>
  );
}
